import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
// import "bootstrap/js/src/collapse";
// import "bootstrap/js/src/dropdown";
import { Switch, Route, Redirect } from 'react-router-dom'
import About from './Components/About'
import Contact from './Components/Contact'
import Navbar from './Components/Navbar'
import Hectagon from './Components/Hectagon'
import Blog from './Components/Blog'
import Celebrity from './Components/Celebrity'
import ManualSearch from './Components/ManualSearch'
import Community from './Components/Community'
import Offshore from './Components/Offshore'
import AssetTracing from './Components/AssetTracing'
import DeepLearning from './Components/DeepLearning'
import Privacy from './Components/Privacy'
import Terms from './Components/Terms'
import { Box } from "rebass";


const App = () => {
  return (
    <Box
       sx={{
         margin: "3% 5% 1%",
         padding: "0px",
       }}
     >
		
		<Navbar />
		<Switch >
			<Route exact path="/" component={Blog} />
			{/*<Route path="/why-are-planning-application-public-records-so-important" component={About} />*/}
			<Redirect exact from='/why-are-planning-application-public-records-so-important' to='/view-planning-applications-for-uk-osint'/>
			<Route path="/view-planning-applications-for-uk-osint" component={About} />
			<Route path="/contact" component={Contact} />
			<Route path="/asset-tracing-and-corporate-investigations" component={Hectagon} />
			<Redirect exact from='/updates' to='/'/>

			<Route path='/celebrity-homes-and-public-records' component={Celebrity} />
			<Redirect exact from='/celebrity-planning-applications-are-public-records' to='/celebrity-homes-and-public-records'/>
			<Route path="/uk-planning-applications-for-osint" component={ManualSearch} />
			<Redirect exact from='/planning-application-databases-for-osint-investigations' to=''/>
			<Route path="/map-communities-with-osint" component={Community} />
			<Redirect exact from='/planning-application-public-records-and-community-dynamics' to='/map-communities-with-osint'/>
			<Route path="/register-of-overseas-entities-osint" component={Offshore} />
			<Redirect exact from='/overseas-entities-and-public-records-and-property-ownership' to='/register-of-overseas-entities-osint'/>

			<Route path="/asset-tracing-uk" component={AssetTracing} />
			<Route path="/deep-learning-osint" component={DeepLearning} />
			<Route path="/privacy-policy" component={Privacy} />
			<Route path="/terms-of-service" component={Terms} />

			<Redirect to="/" />
		</Switch>
	
	</Box>
  
  )
}

export default App
