import React from 'react';
import ReactMarkdown from 'react-markdown';
import network from "../images/network.png";
import carroll from "../images/carroll.png";
import traffic from "../images/traffic.png";
import {Helmet} from "react-helmet";

export default function Community(){
    return(
    <div class='markdown'>
        <Helmet>
            <title>Map Communities with OSINT</title>
            <meta name="description" content="Map communities in the the United Kingdom - Local councils receive thousands of planning applications every year. These applications can provide a wealth of information about the development of communities and the issues that matter to the residents who live there." />
        </Helmet>

      <br/>
      <ReactMarkdown># **Map Communities in the UK: How Planning Application Data can Help**</ReactMarkdown>
      <br/>
      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>In the United Kingdom, local councils receive thousands of planning applications every year. These applications can provide a wealth of information about the development of communities and the issues that matter to the residents who live there. In particular, the comments left by members of the public on planning applications can contain valuable insights that can be used for open-source intelligence (OSINT) investigations. By analysing the comments left on planning applications, we can gain a deeper understanding of community dynamics and the issues that affect them.</ReactMarkdown>
      <br />
      <img class='customimage' src={network} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>## **Inherent Bias**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>It is important to acknowledge the bias of the intelligence that can be gleaned from planning application comments. While these comments can provide valuable insights into community dynamics and issues, it is essential to recognize that most people are compelled to comment out of anger or frustration towards the proposed development. Therefore, the comments may not be representative of the entire community, and the intelligence gathered may be skewed towards those who are most vocal. It is important to keep this in mind and supplement the planning application data with other sources of information to develop a comprehensive understanding of the community dynamics. Additionally, using advanced analytical techniques to identify and correct for bias can help produce more accurate and reliable insights.</ReactMarkdown>
      <br />
      <ReactMarkdown>## **Extracting Relevant Relationships and Entities**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>Using the OpenAI API, we can analyse the comments in planning applications to extract relevant relationships and entities. The API can identify keywords, named entities, and sentiments, which can provide valuable information about the issues that matter most to the residents. This analysis can help build a deeper understanding of the community dynamics and provide insights that can be used in various ways.</ReactMarkdown>
      <br />
      <ReactMarkdown>Below we can see a coment left by Cornish Resident, Mrs Kathryn Elsey;</ReactMarkdown>
      <br />
      <ReactMarkdown>
      ```
      Sadly I am aware that the property known as the cottage would be ripe for development ruining a beautiful wildlife garden next to my property. I consider that the proposal is overdevelopment of the site clearly for the second home market as they as large as possible with little provision for families. I am concerned that density of the site will add to the drainage problems in the area and the site exits are on a dangerous bend where traffic constantly appears to exceed the speedlimits.\nI am most concerned about the two story proposals for three of the houses. This will be completely out of keeping with the area. Originally Widemouth was built with mostly bungelows. This has led a great deal of development into the roofs. That has not been a problem however if two story buildings are permitted how long before these are developed into roof space making three stories.\nIt is possible that the plans are for modern box buildings starting to be fashionable with the wealthy second home owners. These will completely detract from the area.\nFor me personally these buildings will detract from my peaceful enjoyment of my home - my garden and windows will be overlooked and there will be four extra properties in close proximity to me and two up against my fence.\nI would request that all the housing development be restricted to single story with rooms in the roof known as one and a half and ideally that no windows are allowed to overlook my property.
      ```
      </ReactMarkdown>
      <br />
      <ReactMarkdown>And how advanced language models can extract entities and relationships effectively;</ReactMarkdown>
      <br /> 
      <img class='customimage' src={carroll} alt="Home Img"/><br />
      <ReactMarkdown>## **Developing a Larger Network of Residents and Issues**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>The information extracted from planning applications can be used to map communities of residents and issues. By identifying the relationships between residents, groups, and topics, we can understand the complex interconnections within a community. Community detection techniques can then be applied to analyse the network, identify clusters of residents, and reveal the key issues that are important to them. Across the sample of Cornish applications analysed, we can see a clustering around "traffic" as an issue</ReactMarkdown>
      <br /> 
      <ReactMarkdown>The insights gained from analysing planning application data can highlight key community issues that might not be identifiable elsewhere. The comments from residents can uncover issues that have not been adequately addressed or bring to light the concerns of marginalized groups. This information can be used by local councils or campaigning politicians to develop policies that address the needs of the community.</ReactMarkdown>
      <img class='customimage' src={traffic} alt="Home Img"/><br />
      <ReactMarkdown>## **Enriching other data**</ReactMarkdown>
      <br />
      <ReactMarkdown>The potential for planning application data to provide a competitive advantage to politicians during elections can only be fully realized when it is combined with other relevant data sources. By leveraging the insights gained from planning application comments, politicians can develop targeted campaign strategies and policies that align with the needs and priorities of the community. However, to gain a more comprehensive understanding of the community's attitudes and interests, it is necessary to supplement the planning application data with other sources such as social media activity, demographic data, or public opinion polls. The combination of these diverse data sources can help politicians develop a more nuanced understanding of the community dynamics and develop strategies that truly resonate with the community.</ReactMarkdown>
      <br />
      <ReactMarkdown>## **Summary**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>In conclusion, planning application data can provide valuable insights into the community dynamics, opinions, and interests that shape local areas. The use of OpenAI API can help extract relevant relationships and entities, which can then be used to develop a larger network of residents and issues. The insights gained from analysing planning application data can highlight key community issues that might not be identifiable elsewhere. This information can be used by local councils or campaigning politicians to develop policies that address the needs of the community. Signing up for [ReversePP](https://reversepp.com), a planning application database designed for intelligence investigations, can assist in the analysis of planning application data.</ReactMarkdown>
    </div>

    )
  }

  