import React from 'react'
import ReactMarkdown from 'react-markdown'

import {Helmet} from "react-helmet";


export default function Privacy(){
    return(
    <div class='markdown'>
      <Helmet>
            <title>Privacy Policy</title>
            <meta name="description" content="View planning applications to assist with your OSINT investigations in the UK" />
        </Helmet>
        <br />
        <ReactMarkdown>
**ReversePP Privacy Notice**
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Introduction**
</ReactMarkdown>
<br/>
<ReactMarkdown>
This Privacy notice contains important information about the information we collect through this site and through use of our services. It contains details of who we are, how and why we collect, store, use and share personal data together with your rights in relation to your personal data and how to contact us and supervisory authorities in the event you have a complaint. We ask that you read this carefully.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**IMPORTANT INFORMATION AND WHO WE ARE**
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Controller.** ReversePP is a trading name of DF W0RK5 Ltd, which is a company incorporated in England and Wales (Company Registration Number 14696593) ("we", "us" or "our" in this privacy notice). OpenCorporates is the controller and responsible for your personal data.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Contact us**. If you have any questions about this privacy notice or our privacy practices, please contact our data privacy manager in the following ways:
</ReactMarkdown>
<br/>
<ReactMarkdown>
By Email:[dan@reversepp.com](mailto:dan@reversepp.com)
</ReactMarkdown>
<br/>
<ReactMarkdown>
**How to complain.** We hope that our data team will be able to resolve any query or concern you raise about our use of your information. However, you have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK regulator for data protection issues ([https://ico.org.uk/](https://ico.org.uk/)). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Data We Collect**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Personal data means any information that relates to an individual that can be identified. It does not include data where it is not possible to identify the person to whom that data relates (anonymous data). In the course of operating this website and providing services, we collect the following personal data about you.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Information you give us**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- **Identity Data** includes first name, last name, social media handles (Twitter/LinkedIn, etc. if you used this for sign up).
- **Contact Data** includes email address and, if a paid for account may collect data such as, billing address, and telephone numbers.
- **Financial Data** includes payment card details.
- **Transaction Data** includes details about payments to and from you and other details of services you have purchased from us.
- **Technical Data** includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.
- **Profile Data** includes your username and password, purchases made by you, feedback and survey responses.
- **Usage Data** includes information about how you use our website and services.
- **Marketing and Communications Data** includes your preferences in receiving marketing from us and your communication preferences.
</ReactMarkdown>
<br/>
<ReactMarkdown>
We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**How Personal data is collected**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We use different methods to collect data from and about you including through:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- **Direct interactions**. You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, email or otherwise. This includes personal data you provide when you:
  - create an account on our website;
  - subscribe to our service or publications;
  - request marketing to be sent to you;
  - enter a survey; or
  - give us feedback or contact us.
</ReactMarkdown>
<br/>
<ReactMarkdown>
- **Automated technologies or interactions**. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using server logs and other similar technologies.
</ReactMarkdown>
<br/>
<ReactMarkdown>
- **Third parties or publicly available sources.** We will receive personal data about you from various third parties and public sources as set out below:
  - Technical Data from the following parties: analytics providers such as Google based outside the UK;
  - Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe based outside the UK.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**How we use personal data**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Where we need to perform the service we provide
- Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
- Where we need to comply with a legal obligation.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Purposes for which we will use your personal data**
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Marketing.** We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of services purchased, service experience or other transactions.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Change of purpose.** If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
</ReactMarkdown>
<br/>
<ReactMarkdown>
Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**International transfers**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Many of our external third parties are based outside the UK so their processing of your personal data will involve a transfer of data outside the UK. Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
</ReactMarkdown>
<br/>
<ReactMarkdown>
The country to which we transfer your data has been deemed to provide an adequate level of protection for personal data and/or
</ReactMarkdown>
<br/>
<ReactMarkdown>
We have put in place specific contracts approved for use in the UK which give personal data the same protection it has in the UK.
</ReactMarkdown>
<br/>
<ReactMarkdown>
Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Data retention**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you. To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
</ReactMarkdown>

    </div>
    )
  }
