import React from 'react'
import ReactMarkdown from 'react-markdown'



export default function Blog(){
    return(
    <div>
      
      <br/>
      <ReactMarkdown>
        # **ReversePP Blog**
        </ReactMarkdown><br/>
        <ReactMarkdown>
        ## [Why is viewing planning applications important for OSINT?](/view-planning-applications-for-uk-osint)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Asset Tracing and Corporate Investigations using Planning Application data](/asset-tracing-and-corporate-investigations)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Find Celebrity Homes with Planning applications](/celebrity-homes-and-public-records)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [UK planning applications for OSINT investigations](/uk-planning-applications-for-osint)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Map Communities in the UK: How Planning Application Data can Help](/map-communities-with-osint)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Which properties are owned by individuals on the Register of Overseas Entities?](/register-of-overseas-entities-osint)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Asset Tracing - UK](/asset-tracing-uk)
      </ReactMarkdown><br />
      <ReactMarkdown>
        ## [Deep Learning - OSINT](/deep-learning-osint)
      </ReactMarkdown><br />
      

    </div>
    )
  }


  