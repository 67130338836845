import React from 'react';
import ReactMarkdown from 'react-markdown';
import orzabal from "../images/orzabel.jpg";
import rower from "../images/rower.jpg";
import ray from "../images/ray.jpg";
import emin from "../images/emin.jpg";
import bear from "../images/bear.jpg";
import eubank from "../images/eubank.jpg";
import {Helmet} from "react-helmet";


export default function Celebrity(){
    return(
    <div class='markdown'>

        <Helmet>
            <title>Celebrity Homes UK</title>
            <meta name="description" content="Celebrity homes in the UK are sometimes public record and planning applications are commonly used by tabloid journalists." />
        </Helmet>
      <br />
      <ReactMarkdown># **Find Celebrity Homes with Planning applications**</ReactMarkdown>
      <br />
      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>Reverse Planning Permission is an ongoing effort to index publicly available information contained within planning applications. If you would like to learn more about the project please read [this article](https://blog.reversepp.com/view-planning-applications-for-uk-osint) which explains how the project works and how it can be useful in your OSINT investigations. Visit here if you are interested in how this data can be combined with other public data, like the [register of overseas entities](https://blog.reversepp.com/register-of-overseas-entities-osint)</ReactMarkdown>
      <br />
      <ReactMarkdown>If you want to do some searches yourself, click [here](https://reversepp.com)</ReactMarkdown>
      <br /> 
      <ReactMarkdown>
        
        You can check which parts of the UK have been indexed by checking the [updates]( https://blog.reversepp.com/updates) section. This month we have indexed planning applications from the following councils; South Gloucester, North Norfolk, Cheshire West, North Somerset, Sevenoaks, Brentwood, Havant, Rochdale, Wakefield, New Forest, East Herts, Sunderland, Thanet, The Isle of Wight, Woking, Bradford, Torridge, Leeds and Brighton & Hove.
      </ReactMarkdown>
      <ReactMarkdown>
        If you follow have been following the project you will understand that tabloid journalists have been using planning applications to assist in their [investigations]( https://blog.reversepp.com/celebrity-homes-and-public-records) into celebrities and people of public interest to great effect for many years. Anyone who wants to build a property, alter an existing one, change land use, or demolish something has to submit a public planning application. The plans and any associated correspondence are all publicly available so that anyone can comment on them. This also means that curious OSINT practitioners can find celebrity homes – here are a few celebrity planning applications that have been recently indexed by Reverse Planning Permission Search.
      </ReactMarkdown>
      <br />
      <ReactMarkdown>## **Celebrity Applications**</ReactMarkdown>
      <ReactMarkdown>
      Roland Orzabal of Tears for Fears fame applied to have a [gate erected in 2011]( https://developments.southglos.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=LIF4VUOK7T000) in South Gloucester
      </ReactMarkdown>
      <img class='customimage' src={orzabal} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>
      Olympic rower Ben Ainslie [submitted an application]( https://planning.newforest.gov.uk/online-applications/applicationDetails.do?keyVal=_NEWFO_DCAPR_146987&activeTab=summary) in 2007 to conduct some external repairs to his home in New Forest
      </ReactMarkdown>
      <img class='customimage' src={rower} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>
      Ray Parlour, Arsenal footballer and sports pundit for BBC Radio 5 Live and Talksport made an [application](https://publicaccess.brentwood.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=ZZZZW5DJJV011) to Brentwood council asking to thin an oak tree in 2007
      </ReactMarkdown>
      <img class='customimage' src={ray} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>
      Thanet resident, Tracey Emin, famous artist and sculptor, made an [application]( https://planning.thanet.gov.uk/online-applications/applicationDetails.do?keyVal=PCXCSOQEGFJ00&activeTab=summary) in 2018 to turn an old print works into an art studio
      </ReactMarkdown>
      <img class='customimage' src={emin} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>
      Lady Grylls, Bear Grylls’ mother, [applied]( https://publicaccess.iow.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=ZZZTXJIQMS723) to have an extension built on the side of his childhood home on the Isle of Wight in 2012
      </ReactMarkdown>
      <img class='customimage' src={bear} alt="Home Img"/><br />
      <br />
      <ReactMarkdown>
      Elite boxer, Chris Eubank, [applied]( https://planningapps.brighton-hove.gov.uk/online-applications/applicationDetails.do?keyVal=ZZZR7GDMXE663&activeTab=summary) to have some trees pruned in his now demolished former home in Brighton
      </ReactMarkdown>
      <img class='customimage' src={eubank} alt="Home Img"/><br />
      <br />
      <ReactMarkdown> Thanks for taking the time to read this. If you are a Journalist, Investigator or OSINT nerd, please give the tool a go! </ReactMarkdown><br />
    
        <ReactMarkdown>While I would love for this side project to be profitable, that is sadly not the case currently - if you do think it is useful, or could 
        be useful in a future state, then please sign up for a Pro account. Having a handful of subscribers would go a long way to helping me improve the tool. The cost is £8 per month, you can unsubscribe at any time. API access is in development so if you think you would find this data useful, please [get in touch](/contact) and share your thoughts!
        </ReactMarkdown>

    </div>

    )
  }

  