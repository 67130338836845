import React from 'react'
import ReactMarkdown from 'react-markdown'
import first from "../images/first_offshore.jpg";
import second from "../images/second_offshore.jpg";
import third from "../images/third_offshore.jpg";
import four from "../images/four_offshore.jpg";
import saudi from "../images/saudi_offshore.jpg";
import sw from "../images/sw_offshore.jpg";
import {Helmet} from "react-helmet";

export default function Hectagon(){
    return(
    <div class='markdown'>
      <Helmet>
            <title>Register of overseas entities and property ownership</title>
            <meta name="description" content="Through the cross-referencing of planning application data with the Register of Overseas Entities maintained by Companies House, it is often possible to identify properties in the UK that are owned by individuals and investors from other countries." />
        </Helmet>
      <br/>
      <ReactMarkdown># **Which properties are owned by individuals on the Register of Overseas Entities**</ReactMarkdown>
      <br />
      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br /> 

      <ReactMarkdown>Through the cross-referencing of planning application data with the Register of Overseas Entities maintained by Companies House, it is often possible to identify properties in the UK that are owned by individuals and investors from other countries. However, a comprehensive examination of the ownership details across the whole register reveals that, in spite of the implementation of the Overseas Entities Registry to promote transparency, complex offshore corporate structures persist, obscuring true beneficial ownership.</ReactMarkdown>

     <ReactMarkdown>## **The Overseas Entities Register**</ReactMarkdown>
     <br />

      <ReactMarkdown>In the UK, the government has made concerted efforts to address fraud and money laundering, with a heightened sense of urgency stemming from the ongoing conflict in Ukraine. Of particular concern are the alleged economic crimes associated with affluent individuals, including Russian oligarchs, who maintain connections in the UK. These circumstances have underscored the need for proactive action to combat any potential illicit activities.</ReactMarkdown><br />
      <ReactMarkdown>As of January this year, overseas entities wishing to participate in property transactions, including the buying, selling, or transferring of property or land in the UK, must complete registration with Companies House. During this registration procedure, these entities are obligated to provide details concerning their registrable beneficial owners or managing officers. This mandatory requirement serves to promote transparency and enables the identification of individuals who possess substantial interests or occupy crucial positions within these entities.</ReactMarkdown><br />
      <ReactMarkdown>The network diagram presented below illustrates the interconnectedness and complexity of companies listed in the overseas entities register, alongside the corresponding planning applications filed by companies bearing the same (or similar) names. Upon analysis of the sample of planning applications, it becomes apparent that a significant number of overseas entities are situated in Jersey, Guernsey, and the Isle of Man. It is worth noting that these locations, being Crown dependencies, operate under their own governance structures, distinct legal frameworks, and financial systems, thereby offering tax-efficient opportunities for property ownership under specific circumstances. Consequently, companies registered within these jurisdictions adhere to laws and regulations that differ from those applicable in the UK.</ReactMarkdown><br /> 
      <img class='customimage' src={first} alt="Home Img"/><br />
      
      
      <ReactMarkdown>## **Replicate the data**</ReactMarkdown><br /> 
      <ReactMarkdown>The rest of this article will delve into the discoveries made following an analysis of the above network, focusing on noteworthy subnetworks. If you wish to conduct your own analysis or identify your own interesting networks, you can follow these steps:</ReactMarkdown>

      <ReactMarkdown> - Begin by accessing the Companies House advanced [search function](https://find-and-update.company-information.service.gov.uk/advanced-search) and downloading a CSV file that contains registered overseas entities. If you want to download more than 5000 entities at a time, you might need to extract it in smaller segments based on specific dates to acquire the entire registry.</ReactMarkdown>

      <ReactMarkdown> - Employ the ReversePP API to cross-reference the obtained data and identify entities with similar names that have also submitted planning applications. Alternatively, you have the option to manually search for these connections using [ReversePP](https://reversepp.com)</ReactMarkdown>

      <ReactMarkdown>By following these steps, you will be able to independently obtain and analyse the data, enabling you to explore the findings discussed in this article or discover other intriguing networks for yourself.</ReactMarkdown><br /> 
      
      <ReactMarkdown>## **Code Snippets**</ReactMarkdown><br /> 
      
      <ReactMarkdown>If you are solely interested in the findings from this analysis, you may skip this section.</ReactMarkdown><br /> 
      <ReactMarkdown>For those interested in utilising the ReversePP API, a comprehensive guidebook is available on [GitHub](https://github.com/dfaram7/reversepp-help-guide). However, if you intend to search for properties owned by an overseas entity, you can employ the following code snippet (using the Python request library):</ReactMarkdown><br /> 
      
      <ReactMarkdown>`applicant = "YOUR_OVERSEAS_ENTITY"`</ReactMarkdown>
      <ReactMarkdown>`pload = &#123;"applicant": applicant, "api-key": “YOUR_REVERSEPPP_API_KEY”&#125;`</ReactMarkdown>
      <ReactMarkdown>`headers = &#123;"Content-Type":"application/json", 'accept': 'application/json'&#125;`</ReactMarkdown>
      <ReactMarkdown>`r = requests.post('https://dev.reversepp.com/applicant_multi', json=pload)`</ReactMarkdown>
      <ReactMarkdown>`print(r.json())`</ReactMarkdown>

      <ReactMarkdown>Please note that the provided code snippet serves as a starting point and should be customised according to your specific requirements and the documentation provided on GitHub.</ReactMarkdown><br />
      <ReactMarkdown>In addition, the Companies House API can be utilised to verify the 'persons of significant control' and 'beneficial ownership' data associated with your overseas entity. If you have not utilised the Companies House API before, you will be required to create an account and generate an API key by setting up an application. This API key will grant you access to the necessary endpoints.</ReactMarkdown><br />
      <ReactMarkdown>The provided code snippet below generates a list of lists that can be easily converted into a DataFrame or .csv format. Each row within the list represents a relationship between an Offshore Entity, Company Location, Beneficial Owner, and Nationality, thereby organising the data in a structured manner for further analysis or storage.</ReactMarkdown><br />
      <ReactMarkdown>`payload = &#123;'q': “YOUR_OVERSEAS_ENTITY”&#125;`</ReactMarkdown>
      <ReactMarkdown>`resp = requests.get('https://api.company-information.service.gov.uk/search/companies', data=payload, auth=(“YOUR_COMPANIES_HOUSE_KEY”, ''))`</ReactMarkdown>
      <ReactMarkdown>`rels_data = []`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for item in resp.json()['items'][:5]: #other non overseas entities might be returned`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if item['company_type'] == 'registered-overseas-entity':`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;try:`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;coy_num = item['company_number']`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rels_data.append([r['companies_house'], item['address']['country'], 'Overseas Location'])`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;payload_two = &#123;'items_per_page': 10&#125;`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;resp_two = requests.get('https://api.company-information.service.gov.uk/company/' + coy_num + '/persons-with-significant-control', data=payload_two, auth=(“YOUR_COMPANIES_HOUSE_KEY”, ''))`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;except Exception as e:`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('first error ' + str(e))`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for entity in resp_two.json()['items']:`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print(entity)`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('\n')`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if entity['kind'] == 'corporate-entity-beneficial-owner':`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rels_data.append([r['companies_house'], entity['name'], entity['kind']])`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rels_data.append([entity['name'], entity['address']['country'], entity['kind']])`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else:`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rels_data.append([r['companies_house'], entity['name'], entity['kind']])`</ReactMarkdown>
      <ReactMarkdown>`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rels_data.append([entity['name'], entity['nationality'], 'Nationality'])`</ReactMarkdown><br />

      
      <ReactMarkdown>ReversePP continues to index applications across the country, but an ongoing dataset of connections between overseas entities, planning applicants, and properties is maintained. If you're interested in this data or would like some custom analysis, you can send your inquiries to dan@reversepp.com.</ReactMarkdown><br />
      <ReactMarkdown>## **JTC Ltd**</ReactMarkdown><br />
      
      <ReactMarkdown>Recent discussions in the House of Lords, transcribed [here](https://hansard.parliament.uk/lords/2023-04-20/debates/C544055E-1BF7-45C0-98EB-A9B671B006D8/EconomicCrimeAndCorporateTransparencyBill), have focused on evaluating the effectiveness of The Overseas Entities Register in addressing transparency concerns. Lord Faulks has highlighted a limitation, stating that currently, only the name of the trustee is published on the register, thereby limiting public access to information about the owners and controllers of trusts and properties. By his estimation, this lack of comprehensive data conceals the ownership details of approximately 7,000 entities encompassing about 20,000 properties</ReactMarkdown><br />
      <ReactMarkdown>To highlight the obscurity surrounding ownership structures, Lord Faulks provides an example of an "entanglement of interests" achieved by comparing Land Registry records with the Overseas Entities Register. In this instance, Lord Faulks mentions that JTC (Suisse) SA is identified as the corporate trustee for Aragon Hall and is listed as a registered overseas entity. However, it is important to note that JTC (Suisse) SA designates JTC plc, the Jersey-based entity of the company, as the beneficial owner, which is not included in the register.</ReactMarkdown><br />
      <ReactMarkdown>JTC is a company that specialises in private client services, with a focus on funds and offshore corporate structures. Similarly to the example given by Lord Faulks, we can cross-reference JTC entities on the Overseas Register with those who have submitted planning applications, revealing that a shopping centre in Brighton also has its true ownership obscured JTC plc.</ReactMarkdown><br />
      <img class='customimage' src={second} alt="Home Img"/><br />

      <ReactMarkdown>By further incorporating data from Companies House and ReversePP, we can expand on the example to reveal a more detailed network of offshore entities and properties whose ultimate beneficial ownership has been obscured due to the absence of JTC Plc from the overseas registry. These corporate structures become increasingly intricate and include other offshore jurisdictions such as The Bahamas and The Isle of Man. The properties owned by these structures are often located in affluent areas of London, are large countryside properties or are high-rise construction projects that are likely valued in the hundreds of millions.</ReactMarkdown><br />
      <img class='customimage' src={third} alt="Home Img"/><br />
      
      <ReactMarkdown>## **AsiaTrust**</ReactMarkdown><br />
      <ReactMarkdown>JTC is not an exceptional case; it exemplifies a broader trend. The pink chart illustrates an initial layer of ownership, where Tizzola Properties Ltd and Finsbury Properties Ltd are both listed on the Overseas Entities Register. However, they indicate Asiatrust Ltd as a corporate beneficial owner, and the trustees of the trust possess the authority to exert significant influence or control over the entity.</ReactMarkdown><br />
      <ReactMarkdown>Based on Hong Kong [Corporate Records](https://di.hkex.com.hk/filing/di/NSForm1.aspx?fn=36130&sa2=an&sid=24460104&corpn=Joyce+Boutique+Holdings+Ltd.&sd=01%2F04%2F2003&ed=23%2F01%2F2014&sa1=cl&scsd=01%2F01%2F2003&sced=16%2F04%2F2018&sc=00647&src=MAIN&lang=EN&), AsiaTrust Ltd appears to be a subsidiary of AsiaCiti Trust Pacific Limited, which is ultimately connected to AsiaCiti Trust. AsiaCiti Trust has gained significant attention as a result of its involvement in both the Paradise Papers and Pandora Papers data leaks. The revelations from the Pandora Papers disclosed AsiaCiti Trust's involvement in assisting Kirill Androsov, a former aide to Russian leader Vladimir Putin, in establishing offshore companies to facilitate transactions with Russian oligarch Oleg Deripaska. Furthermore, investigations conducted by the ICIJ and the NZ Herald revealed that the company provided assistance to fugitive Moldovan politician Vladimir Plahotniuc.</ReactMarkdown><br />
      <img class='customimage' src={four} alt="Home Img"/><br />
      
      <ReactMarkdown>## **Impact on UK Citizens and possible solutions**</ReactMarkdown><br />
      
      <ReactMarkdown>Foreign investment in the UK property market presents a mixed landscape of advantages and challenges. On the positive side, it has the potential to deliver substantial economic advantages, including heightened investment levels and job opportunities. However, it also presents certain risks, such as the potential escalation of property prices and exacerbation of housing affordability issues for UK residents. By utilising publicly accessible data from Corporate Records, Land Registration records and planning applications, private enterprises and policymakers can effectively address the unintended consequences associated with overseas property investments.</ReactMarkdown><br />
      <ReactMarkdown>Saudi Arabian Nationals, as well as some of the Saudi Royal Family, have made significant investments in the UK property market. Understanding these trends can allow stakeholders to better comprehend the dynamics of the UK economy. Typically, Saudi Arabian investors have concentrated their investments in particular areas and property types, such as prime London real estate. By examining these patterns, stakeholders can gain valuable insights into the investment strategies and motivations of Saudi investors, and the factors that underpin their investment decisions. Understanding Saudi investment in the UK property market can also help to inform policy decisions around foreign investment and housing affordability. If foreign investment is seen to be contributing to housing affordability challenges for UK residents, policymakers may choose to implement measures to restrict foreign investment or incentivize domestic investment. Conversely, if foreign investment is seen to be driving economic growth and creating jobs, policymakers may choose to encourage and facilitate more foreign investment in the property market. Below is a subset of entities showing a network of where some Saudi Arabian investors have chosen to invest and who they have invested alongside which could provide valuable insights for a wide range of stakeholders, from investors and developers to policymakers and regulators.</ReactMarkdown><br />
      
      <img class='customimage' src={saudi} alt="Home Img"/><br />
      <ReactMarkdown>In addition, understanding foreign investment within a particular postcode can provide insights into the supply and demand dynamics of the local property market. Foreign investment can have a significant impact on local property prices and the availability of housing stock. By analysing foreign investment within a specific postcode, stakeholders can gain a better understanding of the level of demand for property in that area and how this demand is being met. Below we can see a chart detailing foreign investment in SW1 (London), we can see that the British Virgin Islands is a key node within the network</ReactMarkdown><br />
      <img class='customimage' src={sw} alt="Home Img"/><br />
      
      <ReactMarkdown>## **Conclusion**</ReactMarkdown><br />
      <ReactMarkdown>In the pursuit of transparency and combating illicit activities in the UK property market, the analysis of ownership details has exposed the persisting complexity of offshore corporate structures. Tabloid journalists are not only [viewing planning applications](https://blog.reversepp.com/celebrity-homes-and-public-records) to target celebrities. While foreign investment brings economic benefits, it also presents challenges, such as rising property prices and housing affordability concerns. However, by harnessing the power of publicly available data from Corporate Records, Land Registration, and planning applications, and leveraging innovative tools like [ReversePP](https://reversepp.com), private companies and policymakers can effectively navigate the intricate networks of ownership, uncover hidden connections, and address the unintended consequences of overseas property investment. ReversePP plays a pivotal role in indexing connections between overseas entities, planning applicants, and properties, empowering stakeholders to make informed decisions and promote transparency, integrity, and fairness within the UK property market.</ReactMarkdown><br />
      <ReactMarkdown></ReactMarkdown><br />
      <ReactMarkdown></ReactMarkdown><br />

      
      
      
      


     
      
      

            
    </div>
    )
  }
