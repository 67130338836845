import React from 'react'
import ReactMarkdown from 'react-markdown'
import hectagon1 from "../images/hectagon1.jpg";
import hectagon2 from "../images/hectagon2.jpg";
import ppch from "../images/ppch.jpg";
import woolhouse from "../images/woolhouse.jpg";
import landregistry from "../images/landregistry.jpg";
import {Helmet} from "react-helmet";


export default function Hectagon(){
    return(
    <div class='markdown'>

        <Helmet>
            <title>Asset Tracing using Planning Applications</title>
            <meta name="description" content="Asset tracing is an important aspect of various industries such as finance, law, and intelligence. It involves locating and identifying assets such as property, bank accounts, and companies using public records." />
        </Helmet>
        <br />
      <ReactMarkdown># **Asset Tracing and Corporate Investigations using Planning Application data**</ReactMarkdown>

      
      <br />
      <img class='customimage' src={ppch} alt="Home Img"/><br />
      <br />   

      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br /> 
      <ReactMarkdown>Asset tracing is an important aspect of various industries such as finance, law, and intelligence. It involves locating and identifying assets such as 
        property, bank accounts, and companies, which can be linked to a person or organisation of interest. Open Source Intelligence (OSINT) is a 
        crucial tool for asset tracing, providing a wealth of publicly available information that can be leveraged to support investigations. </ReactMarkdown><br />
      
      <ReactMarkdown>Planning application data refers to the information submitted by individuals or organisations when applying for planning 
        permission from local authorities. This information is usually made publicly available and can provide valuable insights into the 
        ownership and use of properties and land. Planning application data is particularly useful for asset tracing investigations as it can 
        provide information on the ownership and use of properties, including the names of owners, directors, and company structures, as well as 
        the type of use and purpose of the property.</ReactMarkdown><br />
      
      <ReactMarkdown>Planning application data can supplement data that you already use for asset tracing such as company registration data, property ownership data, and social media profiles. 
        By fusing planning application data with these other sources of information, it is possible to build a comprehensive picture of an 
        individual or organisation, including their assets and affiliations.</ReactMarkdown><br />

      <ReactMarkdown>One practical use case of combining planning application data with other OSINT sources can be seen in 
        investigations involving corporate structures. By fusing planning application data with company registration data, it is possible to 
        identify the ownership and use of properties by companies and their subsidiaries. This can provide valuable insights into the ownership 
        and control of properties, which can be useful for asset tracing purposes.</ReactMarkdown><br />

        <ReactMarkdown>## **London**</ReactMarkdown>

        <ReactMarkdown>Unsurprisingly, in London most planning applications are made by organisations that have lots of segregated real estate such as supermarkets, 
          fast food restaurants and other national chains. Perhaps more unexpectedly are the following; </ReactMarkdown><br />

        <ReactMarkdown>
        - • [Mr Neil Scoresby](https://development.towerhamlets.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=DCAPR_121117), a BT employee who has made 100s of applications for the installation and maintenance of payphones
        </ReactMarkdown><br />
        <ReactMarkdown>
          - • [Daylight Signs](https://idoxpa.westminster.gov.uk/online-applications/applicationDetails.do?keyVal=9506707AD7&activeTab=summary) who have made countless applications to erect estate agent signs as a result of some advertising regulations enacted in 2020
          </ReactMarkdown><br />
          <ReactMarkdown> - • The Crown Estate has to go through the planning application process the same as everyone else, even for palace [pruning works](https://idoxpa.westminster.gov.uk/online-applications/applicationDetails.do?keyVal=9509421TPO&activeTab=summary)
          </ReactMarkdown><br />

          <ReactMarkdown>If you would like to see the data used for the next section you can view it [here](https://github.com/dfaram7/planning_sample). 
            Data used to create the larger chart at the top of the page is also available as well as further information on the [register of overseas entities](https://blog.reversepp.com/register-of-overseas-entities-osint).</ReactMarkdown><br/>

            <ReactMarkdown>## **Hectagon Assets Limited**</ReactMarkdown>
            <br />

            <ReactMarkdown>Aside from the obvious and unusual, when looking at the most frequent planning applicants in London on [Reverse Planning Permission](https://reversepp.com), there are several entities that follow naming
              conventions typical of companies that you would find in more complex corporate structures operated by HNWI and their family offices. When filtering the most popular applicants for strings such as;
               "Trust", "Capital" or "Asset" you can find some interesting investigative starting points. 
            </ReactMarkdown>

            <ReactMarkdown>The below charts combine data from [reversepp.com](https://reversepp.com) and Companies House to demonstrate how planning application 
              data can bolster your asset tracing and corporate structure investigations. We will look at Hectagon Assets Ltd who have made lots of planning applications at one postcode in East London. 
            </ReactMarkdown>



            <img class='customimage' src={hectagon1} alt="Home Img"/><br />
            <img class='customimage' src={hectagon2} alt="Home Img"/><br />

            <ReactMarkdown>Hectagon Assets Ltd has made over 100 planning applications to flats that exist within Wool House, 74 Back Church Lane, London, E1 1AF. Interestingly, this warehouse was the setting for the second series of the BBC television series Dragons Den!
            When combining information from different OSINT sources you can start to build a picture of the building's history and it's ownership;
            </ReactMarkdown>

            <img class='customimage' src={woolhouse} alt="Home Img"/><br />

            <ReactMarkdown> - • [Wool House's](https://en.wikipedia.org/wiki/Wool_House#:~:text=The%20residential%20conversion%20was%20completed,personal%20fortune%20of%20%C2%A3835m).
              Wikipedia page suggests that the building is owned by Octagon Investments which is part of the Yianis Group owned by property magnate John Christodoulou. The paragraph isn't referenced
              so we might, with further investigation, be able to confirm a geometry themed naming convention for the Yianis Group or make a Wikipedia edit!
            </ReactMarkdown>

            <img class='customimage' src={landregistry} alt="Home Img"/><br />

            <ReactMarkdown> - • Land registry info shows that it is Hectagon Assets Ltd that owns the building and it was last bought in 2014 for £85m.
            </ReactMarkdown>

            <ReactMarkdown>- • All of the applications made by Hectagon Assets Ltd are to apply for a "Certificate of Lawfulness" which is usually an application made 
            retrospectively when you - or the previous owner - didn't realise that planning rules had been broken. In some cases, a Certificate of Lawfulness – long known as a '4 Year Rule' - application can help you out. Homeowners 
              and developers can apply for a Certificate of Lawfulness to retrospectively bring their property into line with local council regulations. Across the sample of applications I looked at, the applications were 
              seeking to demonstrate that those flats had been used as resedential accomodation for 4 years (mostly by providing tenancy agreements) and therefore the council should let them continue to be used this way.
            </ReactMarkdown>

            <ReactMarkdown> - • One attempt to convert the building into 600 homes for Key Workers and Students was [rejected](https://democracy.towerhamlets.gov.uk/Data/Development%20Committee/20030319/Minutes/$74%20Backchurch%20Lane.doc.pdf) by 
              the council in 2003 for reasons outlined in the document. This could be a risk to reputation for both the council and Hectagon Assets Ltd if either were found to be making exceptions or 
              using unfair advantages to develop more premium housing at the expense of less wealthy residents. Don't forget that tabloid journalists often [view planning applications](https://blog.reversepp.com/celebrity-homes-and-public-records)</ReactMarkdown>

              <ReactMarkdown>Have a go at incorporating planning application data into your investigations with the [free or pro version](https://reversepp.com) of Reverse Planning Permission or [request API access](https://reversepp.com/contact)
            </ReactMarkdown>
    </div>
    )
  }
