import React from "react";
import './navbar.css'


const Navbar = () => {
  return (

        <header class='head-bgcolour'>
              <nav class="navbar navbar-expand-lg navbar-light " >
                <a class="navbar-brand brand-name" href="https://reversepp.com">ReversePP</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                          <a class="nav-link headerachor" style={{color: "white"}} href="https://reversepp.com">Home</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link headerachor" style={{color: "white"}} href="https://blog.reversepp.com/">Blog</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link headerachor" style={{color: "white"}} href="https://search.reversepp.com">Search</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link headerachor" style={{color: "white"}} href="https://dev.reversepp.com">API</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link headerachor" style={{color: "white"}} href="https://blog.reversepp.com/contact">Contact</a>
                        </li>
                      </ul>
                </div>
              </nav>
          </header>
  );
};

export default Navbar;
