import React from 'react'
import ReactMarkdown from 'react-markdown'
import planning from "../images/planning.jpg";
import stockport from "../images/stockport.jpg";
import map from "../images/map.jpg";
import funny from "../images/funny.jpg";
import {Helmet} from "react-helmet";

export default function ManualSearch(){
    return(
    <div class='markdown'>

        <Helmet>
            <title>UK planning applications for OSINT investigations</title>
            <meta name="description" content="UK Planning Application Portals, which every local authority is mandated to provide, is a valuable yet often overlooked resource for OSINT investigators" />
        </Helmet>
        <br/>
      <ReactMarkdown># **UK Planning Applications for OSINT investigations**</ReactMarkdown>

      
      <br />
      <img class='customimage' src={planning} alt="Home Img"/><br />
      <br />   

      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br /> 

  
      <ReactMarkdown>OSINT investigators face various challenges due to the differences in publicly available records across countries. For instance, researching a subject in the USA provides a wealth of information, while different legislation in the UK can make it more challenging to locate addresses or trace assets. However, there is still a considerable amount of data to examine in the UK if one is willing to conduct thorough research.</ReactMarkdown><br />
      
        <ReactMarkdown>## **Planning Portals**</ReactMarkdown>

      <ReactMarkdown>The Planning Application Portals, which every local authority is mandated to provide, is a valuable yet often overlooked resource for OSINT investigators in the UK. Public planning applications must be submitted by anyone intending to construct a property, modify an existing one, change land use, or demolish a structure. The related plans and correspondence are accessible to the public, allowing anyone to comment on them. Though personal information such as addresses and phone numbers may sometimes be redacted, names, addresses, and business details are generally available. As most councils use similar software, the methodology below is typically applicable to most regions of the country.</ReactMarkdown><br />
      
      <ReactMarkdown>## **Find the right local authority**</ReactMarkdown>

      <ReactMarkdown>You need to identify the correct local authority first. A Google search for *town name* + *local authority* will usually return the correct council in the first handful of results. Be aware that big conurbations are split up into many smaller local authorities that will have their own planning portal. The central UK government has a tool that can help find the right authority [here](https://www.gov.uk/search-register-planning-decisions). </ReactMarkdown><br />
      <ReactMarkdown>To find the planning portal, a search for *town name* + *planning portal* or *local authority* + *planning portal* will usually find the right website - *inurl:.gov.uk/online-applications/* + *local authority* is also a useful Google Dork to find the right page. For example if I want to research someone who lives in Stockport, a search for *Stockport Planning Portal* will take me to the [correct council website](https://www.stockport.gov.uk/find-planning-applications).</ReactMarkdown>
      <br />
      <img class='customimage' src={stockport} alt="Home Img"/><br />
      <br />  

        <ReactMarkdown>## **Search Options**</ReactMarkdown>

        <ReactMarkdown>There are multiple methods to conduct a search on the Planning Application Portals. You can search by using a keyword, address, or postcode. In addition, advanced searches may allow you to search by applicant name. The portals also provide weekly or monthly lists of the latest applications. Furthermore, the map feature allows you to view and filter applications based on their location and date. However, it's important to note that scrolling beyond the local authority's jurisdiction will prevent you from accessing results from other council areas. You will need to visit the planning portal for that specific area. Additionally, you will need to zoom in to view the location for each planning application.</ReactMarkdown><br />
        <br />
      <img class='customimage' src={map} alt="Home Img"/><br />
      <br /> 

            <ReactMarkdown>## **What's In A Planning Application?**</ReactMarkdown>
            <br />

            <ReactMarkdown>The quantity of accessible information is contingent on the size and nature of the planning application. At a minimum, there will be an initial application that typically contains the name and address of the occupant/applicant, along with any building plans. Additionally, the council will provide a rationale regarding whether the proposed building work can proceed. All documents relevant to that location can be accessed and downloaded from the documents tab, which displays the files in reverse chronological order. While sensitive personal information is typically redacted from initial applications, correspondence between councils and applicants may be more inconsistent, making it possible to uncover phone numbers, email addresses, and other personal data. Furthermore, the "Have Your Say" tab publishes comments from third parties, which frequently consist of remarks from local residents. Comments are usually quite benign and consist of nothing more than the views of other local statutory bodies, but when an application is unpopular or controversial it can be very informative - [and amusing!](https://pa.shropshire.gov.uk/online-applications/applicationDetails.do?activeTab=neighbourComments&keyVal=QUPE1QTDG3T00&neighbourCommentsPager.page=2).
            </ReactMarkdown>

          

            <img class='customimage' src={funny} alt="Home Img"/><br />


            <ReactMarkdown>If you do a lot of UK OSINT it's often possible to combine queries of local planning systems with other resources like the Land Registry and Companies House to build detailed profiles of both individuals and businesses.
            </ReactMarkdown>

            


            <br /> 

            <ReactMarkdown>## **ReversePP**</ReactMarkdown>
            <br />

            <ReactMarkdown> Sometimes you might not know which local authority to search in, especially at the beginning of an investigation when your findings are sparse. You can [search](https://search.reversepp.com) across all the councils indexed by ReversePP in this scenario and sometimes you might even discover an address or asset that you might have missed otherwise! 
            </ReactMarkdown>

            <ReactMarkdown>Lastly, big thanks to nixintel for the original article! I learned a bunch about the planning portal search functionality [here](https://nixintel.info/osint-tools/using-the-uk-planning-system-for-osint/).
      </ReactMarkdown>


            
    </div>
    )
  }
