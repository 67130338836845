import React from 'react'
import ReactMarkdown from 'react-markdown'

import {Helmet} from "react-helmet";


export default function Terms(){
    return(
    <div class='markdown'>
      <Helmet>
            <title>Terms of Service</title>
            <meta name="description" content="View planning applications to assist with your OSINT investigations in the UK" />
        </Helmet>
        <br />
        <ReactMarkdown>
**ReversePP Terms of Service**
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Introduction**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Company Mission: ReversePP is dedicated to aggregating and making accessible planning application data from the US and UK for the benefit of various stakeholders including individuals, businesses, and academic researchers.
- Service Overview: ReversePP offers access to planning application data through our website, API, and other data products ("ReversePP Services").
</ReactMarkdown>
<br/>
<ReactMarkdown>
**User Eligibility**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- General Access: The website is accessible to the general public for personal, commercial and non-commercial use.
- Permitted Users: Special access or services may be provided to journalists, NGOs, academics, and other users contributing to public benefit research. The determination of Permitted User status is at ReversePP's discretion.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Usage Restrictions**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Prohibited Use: Automated data scraping (except for search engine indexing), extraction of large data volumes in a short period, and any use that places excessive demand on our servers are prohibited.
- API Usage: Access to our API requires an API key, available under specific terms, including free or fee-based options depending on usage.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Data Accuracy and Liability**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Disclaimer: ReversePP sources data from various entities and does not guarantee its accuracy or completeness. The data on property and ownership that is available through ReversePP ("planning data") is drawn from many different sources. In some cases the source data have errors which we have not yet detected and in other cases the data has been provided in very complex and impenetrable formats, which may have given rise to errors in the way it is presented on ReversePP.
- Your use of the company data is entirely at your own risk. Original sources are available to reference where possible.
- Limitation of Liability: ReversePP's liability is limited, particularly in cases of data inaccuracy, website functionality, and potential data loss.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Registration and Account Security**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Account Responsibility: Users are responsible for maintaining the confidentiality of their account details and for all activities under their account.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Database**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- We source the information in our databases from government and other sources through a variety of means including: directly from government websites and APIs, from publicly available datasets, or through Freedom of Information requests. We spend a lot of time, effort, and even money in getting this data and turning it into a workable and highly usable resource.
- We do not claim any rights over the information we receive from our government sources, and attribute them whenever possible.
- ReversePP and any data is provided "as is" and on an "as available" basis without any representations or warranties of any kind (whether express or implied by law) to the maximum extent permitted by applicable law, including the implied warranties of satisfactory quality, merchantability, fitness for a particular purpose, non-infringement, compatibility, security, integrity and accuracy.
- Under no circumstances will we be liable for any of the following losses or damage (whether such losses were foreseen, foreseeable, known or otherwise): (a) loss of data; (b) loss of revenue or anticipated profits; (c) loss of business; (d) loss of opportunity; (e) loss of goodwill or injury to reputation; (f) losses suffered by third parties; or (g) any indirect, consequential, special or exemplary damages arising from the access to or use of the website, company data or any components, features or products of ReversePP, regardless of the form of action.
- In addition, our aggregate liability to you or anyone associated with you, regardless of the theory of liability, whether such liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation, for any claim, breach or default under or in connection with these terms or the access or use of the website, company data or any ReversePP products or components, will not exceed the amount of £500 (GPB) if you are located outside of the United States of America or $500 USD if you are located in the United States of America.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Privacy Policy**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Data Protection: ReversePP adheres to applicable data protection laws, including GDPR. Our detailed Privacy Policy outlines how we handle personal data.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Modifications to Terms**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Updates: ReversePP reserves the right to modify these Terms at any time. Continued use of the Services signifies acceptance of the revised Terms.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Contact and Feedback**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Inquiries: For any questions or feedback regarding these Terms or ReversePP Services, users can contact dan@reversepp.com
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Governing Law**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- If you are accessing this website and/or API as a consumer, the laws of England and Wales apply to these terms, although if you are resident elsewhere you will retain the benefit of any mandatory protections given to you by the laws of that country. Any disputes will be subject to the non-exclusive jurisdiction of the courts of England and Wales. This means that you can choose whether to bring a claim in the courts of England and Wales or in the courts of another country in which you live.
</ReactMarkdown>
<br/>
<ReactMarkdown>
- If you are not accessing this website and/or API as a consumer, these terms and conditions and any dispute or claim arising out of, or in connection with, these terms and conditions, their subject matter (including use of this website, the API or the data) or formation (including non-contractual disputes or claims) will be governed by, and construed in accordance with, the laws of England and Wales. You and us both irrevocably agree that the courts of England and Wales will have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, these terms, their subject matter or formation (including non-contractual disputes or claims).
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Notwithstanding the foregoing, ReversePP has and hereby reserves the right to enforce these Terms and these rights in any court of the world (in which case, OpenCorporates may opt to require that these Terms to be interpreted and governed under local law).
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Commitment to Security**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- ReversePP is committed to protecting the security and integrity of all personal data collected through our services. We understand the importance of safeguarding our users' privacy and the information they search for.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Security Measures**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- To this end, we employ robust cybersecurity measures, which include, but are not limited to:
  - The use of secure servers and encryption technologies to protect data during transmission and storage;
  - Implementing strict access controls and authentication protocols;
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Protection of Searches**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- We recognise that the searches conducted on our platform may contain sensitive information. We have implemented best efforts to ensure that search data is protected against unauthorised access, disclosure, alteration, and destruction.
- These efforts include:
  - Encrypting search queries and results where appropriate;
  - Limiting access to search data to authorized personnel only;
  - Monitoring our systems for potential unauthorised access and security breaches.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**User Responsibility**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- While we take significant steps to protect your data, we also advise our users to maintain good cybersecurity practices on their end. This includes using strong, unique passwords for their accounts and being cautious about the information they share online.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Reporting Security Concerns**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- We encourage our users to report any security concerns or incidents related to ReversePP's services. Please contact us immediately if you suspect any compromise of your data or any unauthorized use of the service.
</ReactMarkdown>

<ReactMarkdown>
**Public Records Statement**
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Introduction**
</ReactMarkdown>
<br/>
<ReactMarkdown>
This Privacy Notice explains how ReversePP collects, stores, uses, and shares personal information in the course of our activities as an aggregator of US and UK planning applications. We are committed to ensuring the privacy and security of the personal information we process. We encourage you to read this notice carefully.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Our Mission**
</ReactMarkdown>
<br/>
<ReactMarkdown>
ReversePP's mission is to enhance transparency and accessibility in the realm of planning applications in the US and UK. We aggregate data related to planning applications, including details about the applications, entities, and individuals involved, for the benefit of the public, researchers, and businesses.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Who We Are**
</ReactMarkdown>
<br/>
<ReactMarkdown>
ReversePP is a service provided by DF W0RK5 Ltd, a company incorporated in the United Kingdom (Company Registration Number 14696593). As a data aggregator, we collect and make available certain personal data in compliance with data protection laws, including the General Data Protection Regulation ("GDPR").
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Information We Collect and Its Source**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We collect data from public sources related to planning applications, which may include:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Local government planning portals;
- Official public records and registers;
- Planning application documents and related materials.
</ReactMarkdown>
<br/>
<ReactMarkdown>
This information may include personal data such as:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Names of applicants and agents;
- Contact details such as addresses and phone numbers;
- Details about the planning applications.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Access to Collected Information**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Our collected data is available to:
</ReactMarkdown>
<br/>
<ReactMarkdown>
- The general public through the ReversePP website;
- Third parties via API access or bulk download, including businesses, research institutions, and public authorities.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Retention of Personal Information**
</ReactMarkdown>
<br/>
<ReactMarkdown>
We maintain a historical archive of planning application data as part of our mission. This information is retained indefinitely to provide a comprehensive historical record of planning activities.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Legal Basis for Processing**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Our processing of personal data is based on legitimate interests, namely providing transparency in planning applications. We believe the public benefit of this processing outweighs any impact on individual rights.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Data Transfer and Security**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Given the global nature of the internet, the data we collect may be accessed worldwide. We ensure appropriate safeguards for international data transfers and implement security measures to protect against unauthorized access or data breaches.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Your Rights Under GDPR**
</ReactMarkdown>
<br/>
<ReactMarkdown>
Individuals have rights including access to their data, correction of inaccuracies, objecting to processing, and, in some cases, data erasure or portability. Requests can be made through our contact details provided below.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Redaction of Information**
</ReactMarkdown>
<br/>
<ReactMarkdown>
If there are exceptional circumstances (e.g., risk to personal safety), we will update our records to reflect changes in the official public records and may temporarily redact information during this process.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Specific Considerations**
</ReactMarkdown>
<br/>
<ReactMarkdown>
- Minors: If we become aware of data concerning minors in public records, we will restrict access to this information.
- Complaints: If you have concerns about our data processing, you have the right to lodge a complaint with a supervisory authority.
</ReactMarkdown>
<br/>
<ReactMarkdown>
**Contact Us**
</ReactMarkdown>
<br/>
<ReactMarkdown>
For any queries, requests, or complaints regarding our data processing, please contact us at dan@reversepp.com.
</ReactMarkdown>
<br/>
<ReactMarkdown>
Changes to This Privacy Notice
</ReactMarkdown>
<br/>
<ReactMarkdown>
This Privacy Notice may be updated periodically. The latest version will always be available on our website.
</ReactMarkdown>


    </div>
    )
  }
