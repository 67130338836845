import React from 'react'
import ReactMarkdown from 'react-markdown'
import helicopter_1 from "../images/helicopter_1.jpg";
import helicopter_2 from "../images/helicopter_2.jpg";
import candy from "../images/candy.jpg";
import five from "../images/porsche5.jpg";
import richard from "../images/richard.jpg";
import mcdonalds from "../images/mcdonalds.jpg";
import {Helmet} from "react-helmet";


export default function About(){
    return(
    <div class='markdown'>
      <Helmet>
            <title>View planning applications for UK OSINT</title>
            <meta name="description" content="View planning applications to assist with your OSINT investigations in the UK" />
        </Helmet>
        <br />
      <ReactMarkdown># **Why is viewing planning applications important for OSINT?**</ReactMarkdown>
      <br />
      <ReactMarkdown>## **Introduction**</ReactMarkdown>
      <br />   
      <ReactMarkdown>Planning permission applications are public record - much like the [register of overseas entities](https://blog.reversepp.com/register-of-overseas-entities-osint) - in the UK so that people in appropriate neighbourhoods can 
        view proposed building works and, where necessary, add comment so local councils can consider impacts to the community before approving. 
        You can view planning applications dating back to pre-1970 and they are a rich trove of information that can be used 
        during your OSINT investigations.</ReactMarkdown><br />
      <ReactMarkdown>As the maintenance of planning permission portals has been delegated to local councils, there is no overarching database maintained by 
        central government in the UK. This means that in order to leverage the information stored within planning applications you need to know which council's records 
        you need to interrogate which may not be possible depending on the type of investigation you are conducting. Reverse Planning Permission Search is 
        an ongoing effort to consolidate these records so that they can be more easily utilised by researchers, investigators and journalists.</ReactMarkdown><br />
      <ReactMarkdown>Identifying an individual's address in the UK is usually achieved by interrogating electoral or company incorporation registers, this is the underlying data 
        behind 192.com and Companies House. This can usually be fruitful but is wholly reliant on the target of your investigation being listed as a director, person of 
        significant control or completing their electoral paperwork. High net worth individuals or people in the public eye, who are often the target of OSINT investigations, may 
        have several properties in the UK that aren't mentioned in centrally managed public records.</ReactMarkdown><br />
      <ReactMarkdown>Tabloid journalists have actually been using information in [planning applications](https://blog.reversepp.com/celebrity-homes-and-public-records) to great affect already. Below are some examples 
        of where planning applications have become newsworthy;</ReactMarkdown><br />
      <ReactMarkdown>
        - • Singer [Charlotte Church](https://www.walesonline.co.uk/news/wales-news/charlotte-church-wellness-retreat-powys-22622223) had an application to build open an eco-friendly wellness retreat in the Welsh countryside [rejected](https://pa.powys.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=R1C1J7MPKN500) 
      </ReactMarkdown><br />
      <ReactMarkdown>
        - • Ed Sheeran was [permitted](https://publicaccess.eastsuffolk.gov.uk/online-applications/applicationDetails.do?activeTab=details&keyVal=O0FQWTQXGYS00) to build an [orangery](https://www.dailymail.co.uk/tvshowbiz/article-10523995/Ed-Sheeran-granted-permission-build-BURIAL-CHAMBER.html) alongside some other features at his Suffolk property
        </ReactMarkdown><br />
      <ReactMarkdown>
        - • Gordon Ramsay was granted [permission](https://planning.cornwall.gov.uk/online-applications/applicationDetails.do?activeTab=summary&keyVal=PQ0B4AFGKQZ00) to build an ['igloo'](https://www.dailymail.co.uk/news/article-7593441/Gordon-Ramsay-gets-permission-30-000-igloo-cabin-outside-Cornish-home.html) at his home in Cornwall
      </ReactMarkdown><br />
      <ReactMarkdown>
        - • There have been several [applications](https://www.dailymail.co.uk/news/article-11573269/Harry-Potter-star-Rupert-Grint-accused-giving-cynical-sweetener-locals-build-estate.html) and rebuttals made by [Rupert Grint](https://www.dailymail.co.uk/news/article-11573269/Harry-Potter-star-Rupert-Grint-accused-giving-cynical-sweetener-locals-build-estate.html) who has planned on splitting a larger property into smaller homes
      </ReactMarkdown><br />
      <ReactMarkdown>## **Current State of the Tool**</ReactMarkdown><br />
      <ReactMarkdown>If you are an experienced OSINT analyst, journalist or researcher you hopefully recognise the value of being able to browse planning applications nationally. 
        I have included my thoughts below on how I believe this [tool](https://reversepp.com) could be used but I'm sure there are many more practical use cases - [get in touch](/contact) if you use the data in a novel way!
        </ReactMarkdown><br />
        <ReactMarkdown>
        I initially developed his tool for personal use as I was involved in an investigation where I was trying to locate an individual known to be in London. It would be possible to search each of the 
        London Councils individually by surname, (...almost, shout out to Hounslow, Hillingdon and Havering who have an ancient database of un-indexed PDFs), however, this is time consuming and I was likely to 
        run into similar problems in the future. Site searching ("Rupert Grint" "Planning Application" site:gov.uk) is also sub optimal as Google does not index every planning application and there isn't a 
        consistent format within individual councils (Rupert Grint, Mr Grint, R Grint) let alone nationwide (also shoutout to [r/OSINT](https://www.reddit.com/r/OSINT/comments/104caxr/planning_permission_uk/)
         who encouraged me to arrive at this solution).</ReactMarkdown><br />
      
      <ReactMarkdown>As of Jan 2023, I estimate to have over 80% coverage alowing you to view planning applications all over London. While this doesn't sound like a lot, it is over a million records and was sufficient for my 
        investigation. I am hoping that it can be useful to others in its current state. The plan is to continue indexing planning applications across the whole of the UK but this is likely to be a project that lasts many months, perhaps years. A log of indexed locations will be maintained in the [updates](/updates) section. 
        Currently, a free version is available with search results being limited to five or less.
        </ReactMarkdown><br />

      <ReactMarkdown>Reverse Planning Permission is super easy to use, it is a free text search bar with only one toggle. The 'General Search' toggle 
        is for users who aren't searching for people and addresses. A regular search is weighted so that the 'Applicant Name' and 'Location' fields are more prominent which is better suited for traditional OSINT 
        investigations. A 'General Search' removes the weighting so searches can be conducted across all fields, we can see the difference below when searching for 'helicopter'.
        </ReactMarkdown><br />
        <ReactMarkdown>#### Regular Search</ReactMarkdown>
        <img class='customimage' src={helicopter_1} alt="Home Img"/><br />
        <br />
        <ReactMarkdown>#### General Search</ReactMarkdown>
        <img class='customimage' src={helicopter_2} alt="Home Img"/><br />
      <ReactMarkdown>Below I will list some other examples where I believe Reverse Planning Permission can add value to your investigations.</ReactMarkdown><br />
      <ReactMarkdown>## **Asset Tracing**</ReactMarkdown><br />
      <ReactMarkdown>Asset tracing is the process of locating financial assets, property or valuables through investigation, much of which can be achieved by utilising OSINT. 
         Determining a subject's asset profile and whether that profile is sufficient to meet outstanding debts or potential claims can lead to a greater understanding of the target's activities. 
         Once the research has been conducted, the investigators can assist in asset recovery litigation and collections processes.</ReactMarkdown><br />
      <ReactMarkdown>Let us conduct a fictional asset tracing exercise on the Candy brothers, Nick and Christian. </ReactMarkdown><br />
      <img class='customimage' src={candy} alt="Home Img"/><br />
      <ReactMarkdown> At the time of writing (Jan 2023 - there may be more now!), a quick a reverse planning application search reveals two properties in London where the Candy brothers have made an application and two further 'Agent' addresses which warrant further investigation. </ReactMarkdown><br />
      <ReactMarkdown> - [81 Cadogan Place](https://www.rbkc.gov.uk/planning/searches/details.aspx?adv=0&simple=%2f&simpleBatch=20&simSubmit=Search&pgplimg=909&id=PP/02/00561&cn=&type=planningImages&tab=tabs-planning-4)</ReactMarkdown><br />
      <ReactMarkdown> - [3 Lennox Gardens, LONDON, SW1X 0DA](https://www.rbkc.gov.uk/planning/searches/details.aspx?adv=0&simple=%2f&simpleBatch=20&simSubmit=Search&pgplimg=1124&id=LB/02/00134&cn=&type=planningImages&tab=tabs-planning-4)</ReactMarkdown><br />
      <ReactMarkdown> - 41-45 Lind Road, Sutton, Surrey SM1 4PP</ReactMarkdown><br />
      <ReactMarkdown> - 5 Lower Belgrave Street, London, SW1W 0NR</ReactMarkdown><br />
      <ReactMarkdown> Already we have four potential assets and investigative strands to pull on. If we combine this with other OSINT methods we can start to build a richer picture of
        the Candy brother's asset profile. Google Street view shows two Porsche Cayenne vehicles outside 81 Cadogan Place several times over the course of 5+ years - admittedly this is inconclusive without corroborating intelligence but two more assets 
        to investigate in the brother's potential portfolio. </ReactMarkdown><br />
        <img class='customimage' src={five} alt="Home Img"/><br />

    <ReactMarkdown>## **Privacy Due Diligence/Reputation Audit**</ReactMarkdown><br />
      <ReactMarkdown> HNWI, particularly those in London are concerned about their privacy and what information is available about themselves online. Many private intelligence firms 
        will conduct exposure reviews to provide general peace of mind or to identify information for removal via legal means. I believe that existing planning applications can have applicant names redacted or changed if there is a 
        material threat or danger to the property owner. Often, to avoid directly linking themselves to an address, privacy conscious individuals will try to submit an application using a company or agent to obfuscate property ownership.
       As an example, if your brother was a prolific conservationist, any planning applications that could be considered detrimental to the environment could be reputationally damaging. Thankfully his is not the case for 
       Sir Richard Attenborough!</ReactMarkdown><br />
       <img class='customimage' src={richard} alt="Home Img"/><br />
       <ReactMarkdown>## **Company Priorities**</ReactMarkdown><br />
       <ReactMarkdown> Aggregated planning permission data might also be an indicator of company priorities. Mcdonald's are well known for owning commercial property and leasing them to franchise owners. Perhaps, planning
        applications made by 'Mcdonald's Restaraunt Limited' could be indicative of where in London the headquarters element prioritises for investment. While this is speculative in isolation, this information could be used to build an assessment of an organisation's strategy alongside 
        other data. The below map details the year and postcode of a selection of planning applications made by Mcdonald's. Could the red area show a possible prioritisation of South West London in the mid to late 90s? Perhaps the purple and green areas represent later prioritisations North of the River Thames?</ReactMarkdown><br />

        <img class='customimage' src={mcdonalds} alt="Home Img"/><br />

        <ReactMarkdown>## **Summary**</ReactMarkdown><br />
      <ReactMarkdown> Thanks for taking the time to read this. In short, this was a tool I developed for myself which perhaps has broader utility. If you are a Journalist, Investigator or OSINT nerd, please give the tool a go.  </ReactMarkdown><br />
    
        <ReactMarkdown>Purchasing a Pro subscription will go a long way to sustaining the tool if you find it useful. There are over 1000 council run databases and at least 10 "planning portal" providers which means that bespoke technology is required to index each council. Efficiencies are being made all the time but
        it has taken ~ 1 month to attain usable coverage in London. While I would love for this side project to be profitable, that is sadly not the case currently. The costs of maintaining the site and infrastructure are already well 
        over £150 per month and this is certain to increase with additional users and indexing. If this tool proves to be unpopular then it is only a matter of time before it is unsustainable to maintain so if you do think it is useful, or could 
        be useful in a future state, then please sign up for a Pro account. Having a handful of subscribers would go a long way to helping me improve the tool. The cost is £8 per month, you can unsubscribe at any time. API access is available [here](https://dev.reversepp.com), if you would like a key [get in touch](/contact).</ReactMarkdown>
    </div>
    )
  }
